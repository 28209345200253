import {io} from "socket.io-client"
import { showToast } from "../utils";

// options
const option = {
   closeOnBeforeunload:false,
  //  reconnection : false,
  //  transports: ['websocket'],
};

// // console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);
// ** socket client instance
// let url = 'http://ludomultiplayer.betbyheart.in/'
// let url = 'http://localhost:3002'

// let socket =  io(url, option);
// let socket =  io('http://localhost:3002', option);
let socket =  io('https://ludo.betbyheart.com', option);


async function sendRequest(key, dataToSend, callback) {
  if(socket) {
    socket.emit(key, dataToSend, callback)
  }
}

// console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);
export {sendRequest,socket}




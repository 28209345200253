// const createRoom ='create_room';
export const SocketcreateRoom = "create-new-game";
export const SocketjoinRoom = "joinRoom";
export const SocketCheckMs = "checkMs";

export const ScoketleaveRoom = "leaveRoom";

export const SocketreJoinRoom = "reJoinRoom";
export const SocketOnPlayerEnterRoom = "OnPlayerEnterRoom";
export const SocketOnPlayerLeftRoom = "OnPlayerLeftRoom";
export const SocketPlayerList = "PlayerList";
export const SocketRoomListing = "RoomListing";
export const SocketOnRoomListUpdate = "OnRoomListUpdate";

//Raise Event
export const SocketraiseEvent = "raiseEvent"

//Room Properties
export const SocketsetRoomProperties = "setRoomProperties"
export const SocketgetRoomProperties = "getRoomProperties"

export const setRoomPropertiesForLobby = "setRoomPropertiesForLobby"
export const getRoomPropertiesForLobby = "getRoomPropertiesForLobby"

export const SocketOnRoomPropertiesChange = "OnRoomPropertiesChange";
export const SocketchangeRoomBaseProperty = "changeRoomBaseProperty";



//LocalKeys

// const createRoom ='create_room';
export const OnConnectToMaster = "localOnConnectToMaster_chess";
export const createRoom = "localCreateRoom_chess";
export const onCreateRoom = "localOnCreateRoom_chess";
export const onCreateRoomFailed = "localOnCreateRoomFailed_chess";

export const joinRoom = "localJoinRoom_chess";
export const onJoinRoom = "localOnJoinRoom_chess";
export const onJoinRoomFailed = "localOnJoinRoomFailed_chess";

export const reJoinRoom = "localReJoinRoom_chess";

export const LeaveRoom = "localLeaveRoom_chess";
export const OnLeaveRoom = "localOnLeaveRoom_chess";
export const OnLeaveRoomFailed = "localOnLeaveRoomFailed_chess";

export const OnPlayerEnterRoom = "localOnPlayerEnterRoom_chess";
export const OnPlayerLeftRoom = "localOnPlayerLeftRoom_chess";

export const PlayerList = "localPlayerList_chess";
export const OnPlayerList = "localOnPlayerList_chess";

export const RoomListing = "localRoomListing_chess";
export const OnRoomListing = "localOnRoomListing_chess";
export const OnLocalRoomListUpdate = "OnLocalRoomListUpdate_chess";

// Raise Event
export const raiseEvent = "localRaiseEvent_chess";
export const onRaiseEvent = "localOnRaiseEvent_chess";

// Room Properties
export const setRoomProperties = "localSetRoomProperties_chess";
export const getRoomProperties = "localGetRoomProperties_chess";
export const onGetRoomProperties = "localOnGetRoomProperties_chess";
export const onLocalGetRoomProperties = "localOnGetRoomProperties_chess";

export const localSetRoomProperties = "localSetRoomPropertiesLobby_chess";
export const localGetRoomProperties = "localGetRoomPropertiesLobby_chess";
export const OnRoomPropertiesChange = "localOnRoomPropertiesChange_chess";
export const localchangeRoomBaseProperty = "localChangeRoomBaseProperty_chess";

export const setLocalWinner = "setLocalWinner_chess";

// Game Events
export const StartGame = "localStartGame_chess";




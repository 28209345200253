// Decompress data using zlib
import { decode } from "@msgpack/msgpack";
import zlib from "zlib";

const compress = (data) => {
  return new Promise((resolve, reject) => {
    // Inflate the data
    zlib.inflate(new Uint8Array(data), function (err, buf) {
      if (err) {
        reject("Error inflating: " + err); // Reject the promise on error
        return;
      }
      try {
        // Convert the buffer to a string and parse it as JSON
        const jsonData = decode(buf); // Convert to string after inflation
        const parsedData = JSON.parse(jsonData); // Parse the JSON data
        resolve(parsedData); // Resolve the promise with the parsed data
      } catch (parseError) {
        reject("Error parsing JSON data: " + parseError); // Reject the promise on parse error
      }
    });
  });
};

export async function deCompressData(data) {
  try {
    let output = await compress(data);
    return output;
  } catch (error) {
    console.log("errror", error);
  }
}

export async function encodeData(data) {
  try {
    let output = await compress(data);
    return output;
  } catch (error) {
    console.log("errror", error);
  }
}

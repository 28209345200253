import { useEffect, useState } from "react";
import crypto from "crypto-js";

// const [results, setResults] = useState([]);
// useEffect(() => {
//     for (var i = 0; i < 20; i++) {
//         generatedFunction()
//     }
// }, []);

const generateServerSeed = () => {
  // Generate a server seed (you can implement this function according to your needs)
  const randomBytes = randomBytesHex(256);
  return randomBytes;
};

const combine = (serverSeed, clientSeed, nonce) => {
  // Combine server seed, client seed, and nonce (you can customize this according to your requirements)
  return serverSeed + clientSeed + nonce;
};

const getResult = (hashedValue) => {
  let index = 0;
  // result variable
  let result;

  do {
    // get the decimal value from an interval of 5 hex letters
    result = parseInt(hashedValue.substring(index * 5, index * 5 + 5), 16);
    // console.log("result=====>>>", result);
    // increment the offset in case we will need to repeat the operation above
    index += 1;
    // if all the numbers were over 999999 and we reached the end of the string, we set that to a default value of 9999 (99 as a result)
    if (index * 5 + 5 > 129) {
      result = 9999;
      break;
    }
  } while (result >= 1e6);
  // the result is between 0-999999 and we need to convert if into a 4 digit number
  // we a apply a modulus of 1000 and the 4 digit number is further split into a 2 digit number with decimals
  // console.log("[result % 1e4] * 1e-2======>>", [result % 1e4] * 1e-2);
  // return [result % 1e4] * `1e-2`;
  return Math.floor((result % 100) / 16.66) + 1; // Adjust the divisor for range 1-6;
};

const randomString = (length) => {
  // Generate a random string (you can implement this function according to your needs)
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return randomString;
};

const getClientSeed = () => {
  // Generate a client seed (you can implement this function according to your needs)
  return randomString(20);
};
const randomBytesHex = (length) => {
  // Generate random bytes and convert them to a hexadecimal string
  const chars = "0123456789abcdef";
  let randomBytes = "";
  for (let i = 0; i < length * 2; i++) {
    randomBytes += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return randomBytes;
};

let results = [];
let diceValues = [];
const generatedFunction = () => {
  let serverSeed = generateServerSeed();
  //// console.log('Generated Server Seed:', serverSeed);
  serverSeed = crypto.SHA256(serverSeed).toString();

  const clientSeed = getClientSeed();
  const nonce = results.length + 1;

  const combination = combine(serverSeed, clientSeed, nonce);
  const result = getResult(combination);
  diceValues.push(result);
  console.warn("generatedFunction>>", diceValues)
  const newResult = {
    result: result,
    serverSeed,
    nonce,
  };
  return newResult;
};



const hasConsecutiveSixes = (arr) => {
  let count = 0;

  // Iterate over the array
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === 6) {
      count++; // Increment count when a 6 is found
      if (count === 2) {
        return true; // Return true if 6 appears 3 consecutive times
      }
    } else {
      count = 0; // Reset count if the current number is not 6
    }
  }

  return false; // Return false if no consecutive 6's found
};

const randomVal = () => {
  return Math.floor(Math.random() * 6) + 1;
};


const randomValUpToFive = () => {
  return Math.floor(Math.random() * 6);
};

export { generatedFunction,hasConsecutiveSixes,randomVal,randomValUpToFive};

/* eslint-disable react/prop-types */
import React, { useState } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import Text from "../../../../components/Wrapper/Text";
import Button from "../../../../components/button/Button";
import joinroomText from "../../../../assets/JoinRoomText.svg";
import { publish } from "../../../../CustomEvents/event";
import { joinRoom } from "../../../../constants/socketKeys";
import { resetSessionStorage } from "../../../../utils/sessionStorageUtils";
import { showToast } from "../../../../utils";
import Spinner from "../../../../components/Spinner/Spinner";
import {socket} from '../../../../socket/socket'
import {serivce_checkRoomCreateEligibility} from '../../../../../../../service/poolService'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function JoinPrivateRoomModal({ modalShow, onClose,setRoomCode,isPending,setPending,balance}) {
   const navigate = useHistory();
  const [formData, setFormData] = useState({
    roomCode: null,
    roomPassword: null,
  });

  //** handle Change */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //** joi pool */
  const JoinPool = (payload,code) => {
    publish(joinRoom,{
      roomId: formData?.roomCode,
      playerName: 'user00' + Math.floor(Math.random() * 100),
    });
  }

  //** handle join private room */
  const handleJoinPrivateRoom = (e) => {
    if(+balance < 1) {
      showToast('error', "You dont have enough amount to play game");
      return;
   }

    e.preventDefault();
    
    setPending(true);

    socket.emit('GET_GAME_FEE', {roomId : formData?.roomCode});
    
    socket.on("GET_GAME_FEE", async ({success,gameFees}) => {
      if(success === false) {
         showToast('error',"Game not found");
         setPending(false);
         return;
      }
      
      serivce_checkRoomCreateEligibility(gameFees)
      .then(result => {
         if(result) {
          // ** set room code
            setRoomCode(formData?.roomCode);

            // ** payload
            const payload = {
              poolId : formData?.roomCode,
              gameFees : "1000",
              matchCount : 1
            }

            JoinPool(payload,formData?.roomCode);
            
            // reset form data
            setFormData({
              roomCode: '',
              roomPassword: '',
            })

            resetSessionStorage();
         } else {
           navigate.push('/my-profile?showModal=true');
         }
      }).catch(error => {
         console.warn("serivce_checkRoomCreateEligibilityerror",error)
      })
    })

}
  
  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "30%",
        height: "auto",
        maxHeight: "40%",
      }}
      title="Join"
      id="join-private-room-modal"
      enterAnimation={"zoom"}
      leaveAnimation={"zoom"}
    >
      <form 
       onSubmit={handleJoinPrivateRoom}
       className="p-4 h-5/6 overflow-auto text-center flex flex-col gap-4 justify-center items-center bg-transparent"
      >
        {/* room code */}
        <Text size="2xl" title="Enter Private Code" color="text-white" />
        <input
          type="number"
          name="roomCode"
          value={formData?.roomCode}
          onChange={handleChange}
          className="text-center p-3 text-[#fff] w-11/12 bg-transparent border-none outline outline-2 outline-[#ffb702] rounded-lg shadow-lg shadow-yellow-400/40"
          required={true}
        />

        {/* password */}
        {/* <Text size="2xl" title="Enter Password" color="text-white" />
        <input
          type="text"
          name="roomPassword"
          onChange={handleChange}
          className="p-3 text-[#fff] w-9/12 bg-transparent border-none outline outline-2 outline-[#FFB902] rounded-lg shadow-lg shadow-yellow-400/40"
        /> */}
        
        {
          isPending ? <Spinner /> : (
            <Button
              type="submit"
              text={
                <div className="text-shadow uppercase text-xl">
                  {/* <img src={joinroomText} alt={joinroomText} /> */}
                  join
                </div>
              }
              className="play py-2 text-sm md:text-xl mt-3"
              // handleClick={handleNext}
            />
          )
        }
        
      </form>
    </ModalWrapper>
  );
}

export default JoinPrivateRoomModal;

import moment from "moment";

import { LeaveRoom, OnLeaveRoom, OnLeaveRoomFailed, PlayerList, RoomListing, ScoketleaveRoom, SocketPlayerList, SocketRoomListing, SocketchangeRoomBaseProperty, SocketcreateRoom, SocketgetRoomProperties, SocketjoinRoom, SocketraiseEvent, SocketreJoinRoom, SocketsetRoomProperties, createRoom, getRoomProperties, getRoomPropertiesForLobby, joinRoom, localGetRoomProperties, localSetRoomProperties, localchangeRoomBaseProperty, onLocalGetRoomProperties, raiseEvent, reJoinRoom, setRoomProperties, setRoomPropertiesForLobby } from "../constants/socketKeys";

import { showToast } from "../utils";
import { publish, subscribe } from "../ChessBoard/event";
import { sendRequest } from "../Connection/socket";


var baseRoom = {
    roomname: "",
    ttl: 0,//0,seconds,default=0
    isVisible: true,//true,false
    isOpen: true,//true,false
    maxPlayers: 0,//Any number,Defalut 10
    roomPassword: "",//Room Password only applicable if isvisible False
    nickname: ""
}

subscribe(createRoom, async (coreEventData) => {
    var roomData = coreEventData.detail;
    
    console.log("roomData",roomData);

    roomData.userId = localStorage.getItem('userID');

    await sendRequest(SocketcreateRoom, roomData, (serverResponse) => {
        // console.log('sendRequest from server:', serverResponse);
        // publish(onCreateRoom, serverResponse);
    });
});

//** GET ROOM LISTING */
subscribe(RoomListing, async (coreEventData) => {
    // console.log("RoomListingcoreEventData",coreEventData)
    await sendRequest(SocketRoomListing, "", (serverResponse) => {
        // console.log('RoomListing from server:', serverResponse);
    });
});

//** ROOM JOIN */
subscribe(joinRoom, async (coreEventData) => {
    var JoinData = coreEventData.detail;
    console.log("coreEventData",coreEventData);

    JoinData = {
        ...JoinData,
        playerName:localStorage.getItem("userName"),
        userId:localStorage.getItem('userID')
    }
    await sendRequest(SocketjoinRoom, JoinData, (serverResponse) => {
      // console.log('RoomListing from server:', serverResponse);
    });
});

//** RE-JOIN ROOM */
subscribe(reJoinRoom, async (roomDataToJoin) => {
    // console.log("Sending Rejoin response ", roomDataToJoin.detail)
    if (!roomDataToJoin.detail.roomname) {
        return
    }
    if (!roomDataToJoin.detail.uid) {
        return
    }
    await sendRequest(SocketreJoinRoom, roomDataToJoin.detail, (serverResponse) => {
        // console.log("Rejoin response ", serverResponse)
    });
})


//** PLAYER LEAVE ROOM */
subscribe(LeaveRoom, async (coreEventData) => {
    const data = coreEventData.detail;
    var leaveRoomData = {
        roomId: data.roomname,
        playerName:localStorage.getItem("userName"),
        userId:localStorage.getItem('userID')
    }
    await sendRequest(ScoketleaveRoom, leaveRoomData, (serverResponse) => {
        if (serverResponse.success == false) {
            publish(OnLeaveRoomFailed, serverResponse)
            return
        }
        publish(OnLeaveRoom,data);
    })
})

// ** SET Room Properties for lobby **
subscribe(localSetRoomProperties , async (eventData) => {
    var data = eventData.detail;
    // console.log("localSetRoomProperties",data)
    await sendRequest(setRoomPropertiesForLobby, data, (serverResponse) => {
        console.log('lobbyPropertiChanged from server:', serverResponse);
        publish("lobbyPropertiChanged", serverResponse)
    });
})


// ** GET Room Properties
subscribe(getRoomProperties , async (eventData) => {
    var data = eventData.detail;
    console.log("getRoomProperties",data);
    await sendRequest(SocketgetRoomProperties, data, (serverResponse) => {
        // console.log('localgetRoomProperties from server:', serverResponse);
    });
})

subscribe(localGetRoomProperties , async (eventData) => {
    var data = eventData.detail;
    // console.log("getRoomProperties",data);
    await sendRequest(getRoomPropertiesForLobby, data, (serverResponse) => {
        
        // console.log('localSetRoomProperties from server:', serverResponse);
    });
})


// ** SET Room Properties **
subscribe(setRoomProperties , async (eventData) => {
    var data = eventData.detail;
    console.log("localSetRoomProperties",data)
    await sendRequest(SocketsetRoomProperties, data, (serverResponse) => {
        publish(onLocalGetRoomProperties,serverResponse)
        // console.log('localSetRoomProperties from server:', serverResponse);
    });
})
subscribe(localchangeRoomBaseProperty , async (eventData) => {
    var data = eventData.detail;
    // console.log("localchangeRoomBaseProperty",data)
    await sendRequest(SocketchangeRoomBaseProperty, data, (serverResponse) => {
        // publish(onLocalGetRoomProperties,serverResponse)
        // console.log('localSetRoomProperties from server:', serverResponse);
    });
})


//** GET Local Player listing */
subscribe(PlayerList , async (eventData) => {
    var data = eventData.detail;
    await sendRequest(SocketPlayerList, data, (serverResponse) => {
      // console.log('SocketPlayerList from server:', serverResponse);
    });
})


//** RAISE EVENT FOR */

subscribe(raiseEvent, async (coreEventData) => {
    var toShareData = {
        roomname:  coreEventData.detail.roomName,
        EventCode: coreEventData.detail.EventCode,
        SendTo: coreEventData.detail.SendTo,
        CustomData: coreEventData.detail.CustomData,
    }
    console.log("toShareData>>>>",toShareData);
    if(toShareData?.roomname === null) showToast("You are sending room name null")
    await sendRequest(SocketraiseEvent, toShareData, (serverResponse) => {
        // console.log(serverResponse, "Server Response raiseEvent")
    })
})